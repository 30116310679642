/*
  * Obliczanie finalnych wyników
*/
import React, { Component } from 'react'

import './Results.scss';

import {generateArray, stopwatch} from './scripts/handleMethods';

function ResultItem(props){
  return (
    <div className="resultItem">
      <div className="position">
        {props.stats.position}
      </div>
      <div className="name">
        {props.stats.name}
      </div>
      <div className="time">
        {props.stats.result}ms
      </div>
    </div>
  )
}
export default class Results extends Component {
  state = {
    finished: false,
  }
  componentDidMount(){
    //Tworzenie talblicy z losową zawartością
    const randomElements = generateArray(
                            this.props.options.ammountOfElements, 
                            this.props.options.elementLength);
    
    this.props.methods.forEach((v, i) => {
      if(v.selected){//sprawdzanie czy była wybrana do sprawdzania
        //Zmierzenie czasu danej funkcji
        const resultTime = stopwatch(v.method, randomElements, this.props.options.sortBy);
        console.log(`${v.name}: ${resultTime}`);
        this.props.methods[i].result = resultTime;
      }
    })

    this.setState({finished: true});
  }

  renderScoreboard(){
    //Duplikat funkcji by nie zmieniać oryginalnej przy sortowaniu
    let array = [...this.props.methods];
    let temp = []; // zmienna przechowująca komponent

    array = array.sort((a, b) => { // sortowanie według wyniku
      return a.result - b.result;
    })

    let position = 1; // Pozycja w rankingu
    array.forEach((v) => {
      if(!v.selected)
        return;

      temp.push(
        <>
          <ResultItem key={`ri-${position}`} stats={{position, name: v.name, result: v.result}} />
        </>
      )
      position++;
    })

    return temp;
  }
  render() {
    return (
      <div className="results">
        <div className="stage">
          <span className="stageNumber">Krok 4: </span>
          Wyniki
        </div>
        <div className="stageContent">
          {this.renderScoreboard()}
        </div>
      </div>
    )
  }
}

