/*
  * Podgląd wszystkich dostępnych metod,
  * ich opisy oraz możliwość wyboru
*/
import React, { Component } from 'react'
import './AvailableMethods.scss'

function MethodPreview(props){
  return (
    <div className="methodPreview">
      <div className="methodName">
        {props.method.name || "Brak nazwy"}
      </div>
      <div className="methodDescription">
        <div className="methodDescriptionTitle">
          Złożoność
        </div>
        <div className="methodDescriptionItem">
          <span className="bold">Czasowa:</span> {props.method.timePerformance || "Brak Danych"}
        </div>
        <div className="methodDescriptionItem">
          <span className="bold">Pamięciowa:</span> {props.method.memoryPerformance || "Brak Danych"}
        </div>
      </div>
      <label className="methodSelect"> 
        <input type="checkbox"
          onChange={(e)=>{
            if(props.method.id === undefined)
              return;
            
            // Dodaj lub usuń kolejną metodę do sprawdzenia
            props.updateSelection(props.method.id, e.target.checked)
          }}/>
        <span>
          Wybierz
        </span>
      </label>
    </div>
  )
}
export default class AvailableMethods extends Component {
  componentDidMount(){
    this.props.methods.forEach((v, i)=>{
      this.props.methods[i].selected = false;
    })
  }
  generatePreviews(){
    let temp = []; //Zmienna przechowująca strukturę zwracanego elementu
    this.props.methods.forEach((v, i)=>{
      temp.push(<MethodPreview
                  key={`method-${i}`}
                  method={v} updateSelection={this.props.updateSelection}/>);
    })
    return (
      <>
        {temp}
      </>
    )
  }
  render() {
    return (  
      <div className="availableMethods">
       {this.generatePreviews()}
      </div>
    )
  }
}
