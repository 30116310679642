/*
  metody służące do osbługi pomiaru czasu
*/

//Funkcja generująca tablicę z losowymi ciągami znaków
export function generateArray(ammount, length){
  let elements = [];
  for(let i = 0; i < ammount; i++){
    elements.push(generateRandomString(length));
  }
  return elements;
}

export function stopwatch(functionToMeasure, items, orderBy){
  //Tworzy duplikat tablic
  let duplicatedItems = [...items];

  let start = performance.now();
  //Zmniejszane o jeden poniewaz tablice zaczynają sie od 0
  console.log(functionToMeasure(duplicatedItems,orderBy - 1));

  let end = performance.now();
  //Usuwa większość tablicy z pamięci 
  duplicatedItems.splice(0, duplicatedItems.length);
  const result = end - start;
  return result;
}

//Funkcja zwracająca losowy ciąg znaków
function generateRandomString(length){
  let result = ''; //Element do zwrócenia

  /*
    Losowy ciąg będzie się składać z podanych niżej znaków,
    nie dodałem tu znaków takich jak !@#%, ale można je bez problemu dopisać
  */
  let characters       = `ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789`;
  const ammountOfChars = characters.length;

  for ( let i = 0; i < length; i++ ) {
    /*
      Pętla wykona się tyle razy ile ma być znaków w wierszu,
      dodając do zmiennej result kolejny wylosowany znak.
    */
     result += characters.charAt(Math.floor(Math.random() * ammountOfChars));
  }

  return result
}

