/*
  * Dostosowanie parametrów testu
*/
import React, { Component } from 'react'

import './SortOptions.scss';
export default class SortOptions extends Component {
  componentDidMount(){ // Zerowanie danych
    this.props.options.ammountOfElements = 0;
    this.props.options.elementLength = 0;
    this.props.options.sortBy = 0;
  }
  render() {
    return (
      <div className="sortOptions">
        <div className="inputField">
          <span>Ilość elementów:  </span> <br />
          <span style={{fontSize: "14px"}}>(zalecane: 10-100000)  </span> <br />
          <input type="number" onChange={(e)=>{
            this.props.options.ammountOfElements = parseInt(e.target.value);
          }}/>
        </div>

        <div className="inputField">
          <span>Długość wiersza:</span> <br />
          <span style={{fontSize: "14px"}}>(zalecane: 1-256)  </span> <br />
          <input type="number" onChange={(e)=>{
            this.props.options.elementLength = parseInt(e.target.value);
          }}/>
        </div>

        <div className="inputField">
          <span>Po którym znaku sortować:</span> <br />
          <input type="number" onChange={(e)=>{
            this.props.options.sortBy = parseInt(e.target.value);
          }}/>
        </div>
      </div>
    )
  }
}
