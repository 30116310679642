/*
  metody sortujące
*/

//Funkcje sortujące metodą quickSort
export function quickSort(items, sortBy, left = 0, right = items.length -1){
  let index = 0;
  if (items.length > 1) {
    index = quick_partition(items, left, right, sortBy);
    if (left < index - 1) { 
        quickSort(items, sortBy, left, index - 1);
    }
    if (index < right) { 
        quickSort(items, sortBy, index, right);
    }
  }
  return items;
}
function quick_partition(items, left, right, sortBy) {
  const pivot   = items[Math.floor((right + left) / 2)][sortBy];//Element dzielący tablicę
  // Lewy oraz prawy punkt krańcowy
  let i       = left; 
  let j       = right;
  while (i <= j) {
      while (items[i][sortBy] < pivot) {
          i++;
      }
      while (items[j][sortBy] > pivot) {
          j--;
      }
      if (i <= j) {
          quick_swap(items, i, j);
          i++;
          j--;
      }
  }
  return i;
}
function quick_swap(items, leftIndex, rightIndex){
  const temp = items[leftIndex];
  items[leftIndex] = items[rightIndex];
  items[rightIndex] = temp;
}

//Funkcja sortująca metodą przez wstawianie
export function insertionSort (items, sortBy){
  for (let i = 1; i < items.length; i++) {
    let j = i - 1;
    let tmp = items[i];
    while (j >= 0 && items[j][sortBy] > tmp[sortBy]) {
      items[j + 1] = items[j];
      j--;
    }
    items[j+1] = tmp;
  }

  return items;
}

//Funkcja sortująca bąbelkowo
export function bubbleSort(items, sortBy){
  var len = items.length;
    
  for (var i = 0; i < len ; i++) {
    for(var j = 0 ; j < len - i - 1; j++){ 
    if (items[j][sortBy] > items[j + 1][sortBy]) {
      var temp = items[j];
      items[j] = items[j+1];
      items[j + 1] = temp;
    }
   }
  }
  return items;
}

export function buildInSort(items, sortBy){
  return items.sort((a,b)=>{
    if(a[sortBy] < b[sortBy]) { return -1; }
    if(a[sortBy] > b[sortBy]) { return 1; }
    return 0;
  })
}

//sortowanie przez scalanie
export function mergesort(items, sortBy) {
  if (items.length < 2) {
    return items; }
  else {
    var midpoint = parseInt(items.length / 2);
    var leftArr   = items.slice(0, midpoint);
    var rightArr  = items.slice(midpoint, items.length);
    return merge(mergesort(leftArr, sortBy), mergesort(rightArr, sortBy), sortBy);
  }
}

function merge(leftArr, rightArr, sortBy) {
  var sortedArr = [];
  while (leftArr.length && rightArr.length) {
    if (leftArr[0][sortBy] <= rightArr[0][sortBy]) {
      sortedArr.push(leftArr[0]);
      leftArr = leftArr.slice(1)
    } else {
      sortedArr.push(rightArr[0]);
      rightArr = rightArr.slice(1)
      }
    }
  while (leftArr.length)
    sortedArr.push(leftArr.shift());
  while (rightArr.length)
    sortedArr.push(rightArr.shift());
  return sortedArr;
}