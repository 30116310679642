import React, { Component } from 'react';
import './scss/App.scss';
import history from './history';

import {
  Router,
  Switch,
  Route,
} from "react-router-dom";

//Komponenty reacta
import AvailableMathods from './components/AvailableMethods/AvailableMethods';
import SortOptions from './components/SortOptions/SortOptions';

import Results from './components/Results/Results';

// Metody sortujące
import {quickSort, insertionSort, bubbleSort, buildInSort, mergesort} from './components/Results/scripts/sortMethods';

export default class App extends Component {
  state = {
    errors: [], //Opcjonalne błędy podczas wprowadzania danych
  }
  methods = [
    {
      id: 0, //ID metody
      name: "QuickSort", // Nazwa metody
      timePerformance: "O(n log n), do O(n²)", // Złożoność czasowa
      memoryPerformance: "Zależne od implementacji", //Złożoność pamięciowa
      selected: false, //Czy dana metoda ma zostać sprawdzona
      method: quickSort, //funkcja odpowiedzialna za sortowanie tą metodą
      result: 0, //Czas operacji (wynik)
    },
    {
      id: 1,
      name: "Insertion Sort", 
      timePerformance: "O(n²)",
      memoryPerformance: "O(1)",
      selected: false,
      method: insertionSort,
      result: 0,
    },
    {
      id: 2,
      name: "Bubble Sort", 
      timePerformance: "O(n²)",
      memoryPerformance: "O(1)",
      selected: false,
      method: bubbleSort,
      result: 0,
    },
    {
      id: 3,
      name: "Array.prototype.sort()", 
      timePerformance: "Zależnie od silnika",
      memoryPerformance: "Zależnie od silnika",
      selected: false,
      method: buildInSort,
      result: 0,
    },
    {
      id: 4,
      name: "Merge Sort", 
      timePerformance: "O(n * log(n))",
      memoryPerformance: "O(n)",
      selected: false,
      method: mergesort,
      result: 0,
    },
  ]

  options = { 
    ammountOfElements: 0, // Ilośc elementów tablicy
    elementLength: 0, // długość wiersza
    sortBy: 0, // po którym znaku sortować
  }

  //funkcja dodająca lub usuwająca metody które mają zostać przetestowane
  updateSelection = (idToUpdate, status) => {
    this.methods[idToUpdate].selected = status;
  }
  //funkcja sprawdzająca poprawność wprowadzonych danych
  validate = () => {
    let temp = []; //Zmienna tymczasowa na błędy

    // Sprawdzanie poprawności wprowadzonych danych przez użytkownika
    const anySelected = this.methods.some((v) =>{
      return v.selected;
    })
    if(!anySelected){
      temp.push(<div className="errorInfo" key="e0">Zaznacz przynajmniej jedną metodę do sprawdzenia</div>)
    }
    if(this.options.ammountOfElements < 2){
      temp.push(<div className="errorInfo" key="e1">Tablica musi mieć minimalnie dwa elementy </div>)
    }
    if(this.options.elementLength < 1){
      temp.push(<div className="errorInfo" key="e2">Element tablicy musi mieć przynajmniej jeden znak</div>)
    }

    if(this.options.sortBy < 1){
      temp.push(<div className="errorInfo" key="e3">Sortować możesz minimalnie od pierwszego znaku (1)</div>)
    }

    if(this.options.sortBy > this.options.elementLength){
      temp.push(<div className="errorInfo" key="e4">Znak po którym za zostać posortowana liczba musi być mniejszy niż dlugość wiersza</div>)
    }
    
    let result = !temp.length;
    this.setState({errors: temp})
    if(result){
      history.push('/result');
    }
  }
  componentDidMount(){
    // Jeśli ktoś bezposrednio wejdzie na ścieżka /results lub inną zostanie przeniesiony na stronę główną
    history.push('/');
  }
  render() {
    return (
      <Router history={history}>
        <div className="app">
          <Switch>
            <Route path="/result">
              <Results methods={this.methods} options={this.options} />
            </Route>
            <Route path="/">
              <div className="stage">
                <span className="stageNumber">Krok 1: </span>
                Wybierz metody
              </div>
              <div className="stageContent">
                <AvailableMathods methods={this.methods} updateSelection={this.updateSelection}/>
              </div>
              <div className="stage">
                <span className="stageNumber">Krok 2: </span>
                Dostosuj parametry
              </div>
              <div className="stageContent">
                <SortOptions options={this.options}/>
              </div>

              <div className="stage">
                <span className="stageNumber">Krok 3: </span>
                Sprawdź dane i idź dalej
              </div>
              <div className="stageContent" style={{textAlign: "center"}}>
                {this.state.errors}
                <div className="nextButton" onClick={this.validate}>
                  Start
                </div>
                <span style={{fontSize: "15px"}}>Przy większych liczbach to może chwilę potrwać</span>
              </div>
            </Route>
          </Switch>
        </div>
      </Router>
    )
  }
}
